<template>
  <div>
    <div :class="{
        'invitation_block cursor': style_string == '',
        height_c: is_height,
      }" :style="style_string" @click="invitationDilog = true">
      <i class="el-icon-share" style="margin-right: 10px"></i> {{ button_name }}
    </div>
    <!-- 邀请对话框 -->

    <MyDialog :open="invitationDilog" title="邀请成员" @onClose="invitationDilog = false" width="1120px">
      <h4>分享至：</h4>
      <div class="df mt20 mb20">
        <PublicShare></PublicShare>
      </div>
      <div class="invitation">
        <template v-if="false">
          <h3 class="invitation-title">访问码邀请</h3>
          <p class="invitation-text" v-if="!code_describe">
            将
            {{ describe_string }}
            访问码分享给
            {{ describe_two_string }}
            参与者，参与者可在网页中输入访问码访问
            {{ describe_string }}
          </p>
          <p class="invitation-text" v-else>{{ code_describe }}</p>
          <div class="invitation-operate">
            <span class="operate-label">访问码：{{ access_code }}</span>
            <el-button size="mini" class="tag-read" @click="copy" :data-clipboard-text="access_code">
              复制访问码
            </el-button>
          </div>
        </template>
        <h3 class="invitation-title">分享链接</h3>
        <p class="invitation-text" v-if="!share_describe">
          复制链接并分享，
          {{ describe_two_string }}
          参与者打开链接查看
          {{ describe_string }}
          详情
        </p>
        <p class="invitation-text" v-else>{{ share_describe }}</p>
        <div class="invitation-operate">
          <span class="operate-label">分享链接：</span>
          <div class="operate-flex">
            <el-input :value="dizhi" placeholder="" class="three-input"></el-input>
            <el-button size="mini" class="tag-read" :data-clipboard-text="dizhi" @click="copy" type="primary">
              复制链接
            </el-button>
          </div>
        </div>
      </div>
    </MyDialog>

  </div>
</template>

<script>
import Clipboard from "clipboard";
export default {
  props: {
    access_code: {
      // type: Number,
      default: "",
    },
    button_name: {
      type: String,
      default: "分享",
    },
    style_string: {
      type: String,
      default: "",
    },
    describe_string: {
      type: String,
      default: "活动",
    },
    describe_two_string: {
      type: String,
      default: "活动",
    },
    code_describe: {
      type: String,
      default: "",
    },
    share_describe: {
      type: String,
      default: "",
    },
    is_height: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      //邀请对话框显示与否
      invitationDilog: false,
      // 当前页面的地址
      dizhi: window.location.href,
    };
  },
  methods: {
    fenxiang(val) {
      if (val == 1) {
        let urlData = `https://sns.qzone.qq.com/cgi-bin/qzshare/cgi_qzshare_onekey?url=${this.dizhi}&sharesource=qzone&title=互联网教研`;
        window.open(urlData, "_blank");
      }
      if (val == 3) {
        let urlData = `https://connect.qq.com/widget/shareqq/index.html?url=${this.dizhi}&sharesource=qzone&title=互联网教研`;
        window.open(urlData, "_blank");
      }
    },
    // 复制文字函数
    copy() {
      var clipboard = new Clipboard(".tag-read");
      clipboard.on("success", (e) => {
        this.$message.success("已复制到剪贴板");
        console.log("复制成功");
        // 释放内存
        clipboard.destroy();
      });
      clipboard.on("error", (e) => {
        // 不支持复制
        console.log("该浏览器不支持自动复制");
        // 释放内存
        clipboard.destroy();
      });
    },
  },
};
</script>

<style lang="less" scoped>
.invitation_block {
  width: 98px;
  height: 46px;
  background: #ffffff;
  border: 1px solid #3489fe;
  border-radius: 8px;
  font-size: 16px;
  //   width: 60px;
  //   height: 25px;
  //   font-size: 14px;
  //   border: 1px solid #3489fe;
  //   border-radius: 4px;
  //   font-weight: 600;
  color: #3489fe;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  &.height_c {
    height: 46px;
  }
}
/deep/.el-dialog__body {
  padding-top: 0;
}
.invitation {
  .invitation-title {
    font-size: 14px;
    color: #333;
    margin: 30px 0 15px 0;
  }
  .invitation-text {
    font-size: 14px;
    color: #333;
    line-height: 20px;
    margin-bottom: 10px;
  }
  .invitation-operate {
    display: flex;
    align-items: center;
    .operate-label {
      margin-right: 8px;
    }
    .operate-flex {
      flex: 1;
      overflow: hidden;
      display: flex;
    }
  }
}
</style>

